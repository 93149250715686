import React, { Component } from "react";
import "./App.css";
// import "./components/SoundMachine";
import SoundMachine from "./components/SoundMachine";
import "rc-slider/assets/index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Badge, Container, Row, Col } from "reactstrap";
import SimplePanel from "./components/SimplePanel";
import ReactGA from 'react-ga';
import Tr from './components/Locale'

class App extends Component {

	state = {
		showMask: true
	}

	componentDidMount() {
		// google analytics
		ReactGA.initialize({
			trackingId: 'UA-73355513-1',
			debug: false,
			gaOptions: {
				cookieDomain: 'none'
			}
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	removeLoadMask() {
		this.setState({ showMask: false })
	}

	render() {
		return (
			<div className="App">
				<Container className="app-container ">
					<Row>
					
					</Row>
					<Row>
						<Col>
							<SoundMachine ref="sm" onReady={() => this.removeLoadMask()} />
						</Col>
					</Row>
					<Row>
						<Col>
							<SimplePanel title={Tr("Keyboard controls")} className="about">
								<div><code>{Tr("(shift) arrow up/down")}</code> - {Tr("higher/lower BPM")}</div>
								<div><code>{Tr("arrow left/right")}</code> - {Tr("previous/next step according to plan")}</div>
								<div><code>space, s</code> - {Tr("start/stop")}</div>
								<div><code>esc</code> - {Tr("stop")}</div>
							</SimplePanel>
						</Col>
					</Row>
				</Container>
				<div ref="loadMask" className={this.state.showMask === true ? 'loadmask ' : 'loadmask fadeOut'} />
			</div>

		);
	}

}

export default App;